// extracted by mini-css-extract-plugin
export var bullet___7Rq5C = "PlasmicPricing-module--bullet___7Rq5C--fuggd";
export var bullet__bTj9Y = "PlasmicPricing-module--bullet__bTj9Y--lZEhU";
export var bullet__ftwvJ = "PlasmicPricing-module--bullet__ftwvJ--u4Hr4";
export var bullet__hoMcm = "PlasmicPricing-module--bullet__hoMcm--HKTm5";
export var bullet__jPjXz = "PlasmicPricing-module--bullet__jPjXz--kyDtc";
export var bullet__k0W7 = "PlasmicPricing-module--bullet__k0W7--Dl+R5";
export var bullet__vFl0B = "PlasmicPricing-module--bullet__vFl0B--YdmDs";
export var bullet__vpL7D = "PlasmicPricing-module--bullet__vpL7D--2J559";
export var bullet__wmahk = "PlasmicPricing-module--bullet__wmahk--ctB-w";
export var bullet__yhOg3 = "PlasmicPricing-module--bullet__yhOg3--Ww52b";
export var button__oxl0V = "PlasmicPricing-module--button__oxl0V--rOPkL";
export var button__rda84 = "PlasmicPricing-module--button__rda84--M3ot-";
export var button__rgLpO = "PlasmicPricing-module--button__rgLpO--RuVXN";
export var column__l4Txp = "PlasmicPricing-module--column__l4Txp--39Y26";
export var column__pcKpd = "PlasmicPricing-module--column__pcKpd--xXXJd";
export var column__whXIr = "PlasmicPricing-module--column__whXIr--Y0BaP";
export var columns = "PlasmicPricing-module--columns--Hmjvj";
export var faqSection = "PlasmicPricing-module--faqSection--VphoN";
export var faq___42TMp = "PlasmicPricing-module--faq___42TMp--Jd4Sp";
export var faq__avB3G = "PlasmicPricing-module--faq__avB3G--lPC9E";
export var footer = "PlasmicPricing-module--footer--jDrNw";
export var freeBox__aEMuO = "PlasmicPricing-module--freeBox__aEMuO--d+b8D";
export var freeBox__k8Psi = "PlasmicPricing-module--freeBox__k8Psi--oYnsL";
export var freeBox__rXUm = "PlasmicPricing-module--freeBox__rXUm--kcyTX";
export var freeBox__rmXjf = "PlasmicPricing-module--freeBox__rmXjf--KP18l";
export var header = "PlasmicPricing-module--header--S2TXl";
export var plan__axBmv = "PlasmicPricing-module--plan__axBmv--rlU1D";
export var plan__hFByc = "PlasmicPricing-module--plan__hFByc--jvi7E";
export var plan__xGx7I = "PlasmicPricing-module--plan__xGx7I--E31KT";
export var pricingSection = "PlasmicPricing-module--pricingSection--Tqbgl";
export var root = "PlasmicPricing-module--root--pYMNq";
export var svg___5MKva = "PlasmicPricing-module--svg___5MKva--sHyU-";
export var svg___6U5Mt = "PlasmicPricing-module--svg___6U5Mt--g3CBJ";
export var svg__bnSxR = "PlasmicPricing-module--svg__bnSxR--rZObn";
export var svg__eoX3F = "PlasmicPricing-module--svg__eoX3F--+qx2k";
export var svg__g4WpF = "PlasmicPricing-module--svg__g4WpF--5ckV5";
export var svg__oFUlI = "PlasmicPricing-module--svg__oFUlI--Xt7e4";
export var text___4JMmz = "PlasmicPricing-module--text___4JMmz--XDdkY";
export var text__jrjmi = "PlasmicPricing-module--text__jrjmi--vh4th";
export var text__qPrC5 = "PlasmicPricing-module--text__qPrC5--1lkoT";