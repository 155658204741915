// extracted by mini-css-extract-plugin
export var bullet__evGcs = "PlasmicPlan-module--bullet__evGcs--khEjl";
export var bullet__m3UXd = "PlasmicPlan-module--bullet__m3UXd--Y3uFT";
export var bullet__wbUbb = "PlasmicPlan-module--bullet__wbUbb--H20pf";
export var button__dJur9 = "PlasmicPlan-module--button__dJur9--Kp7gU";
export var freeBox___6TtPn = "PlasmicPlan-module--freeBox___6TtPn--FLpoQ";
export var freeBox__ccU7B = "PlasmicPlan-module--freeBox__ccU7B--gkFsV";
export var freeBox__e8Ej = "PlasmicPlan-module--freeBox__e8Ej--ZbmP3";
export var freeBox__yugem = "PlasmicPlan-module--freeBox__yugem--PvorB";
export var root = "PlasmicPlan-module--root--CHHJ+";
export var slotTargetName = "PlasmicPlan-module--slotTargetName--Eh4kH";
export var svg__aBdBf = "PlasmicPlan-module--svg__aBdBf--5nYpL";
export var svg__dE6Dy = "PlasmicPlan-module--svg__dE6Dy--u3DNz";
export var svg__fjFaM = "PlasmicPlan-module--svg__fjFaM--S0u+V";
export var text__dQh2I = "PlasmicPlan-module--text__dQh2I--ylPRn";